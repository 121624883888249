<template>
  <main-template>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col>
            <h1>
              <v-btn color="primary" x-small text @click="goBack">
                <v-icon left> fa fa-angle-left </v-icon>
              </v-btn>
              Aulas
            </h1>
          </v-col>
        </v-row>
        <v-card v-if="gestaoDeAula">
          <v-card-text>
            <h4>Turma: {{ gestaoDeAula.turma.descricao }}</h4>
            <h4>Disciplina: {{ gestaoDeAula.disciplina.descricao }}</h4>
            <h4>
              Carga horária:
              {{
                gestaoDeAula.carga_horaria ? gestaoDeAula.carga_horaria : "[ SEM CARGA HORÁRIA ]"
              }}
            </h4>
            <h4>Professor: {{ gestaoDeAula.professor.nome }}</h4>
          </v-card-text>
          <v-col cols="12" class="py-2">
            <v-btn-toggle borderless>
              <v-btn value="left" @click="() => openModalMonth(1)">
                <span class="hidden-sm-and-down">Frequência</span>
                <v-icon right> mdi-file-pdf </v-icon>
              </v-btn>

              <v-btn value="center" @click="() => openModalMonth(2)">
                <span class="hidden-sm-and-down">Aulas</span>

                <v-icon right> mdi-file-pdf </v-icon>
              </v-btn>

              <v-btn value="right" @click="() => diarioNotas()">
                <span class="hidden-sm-and-down">Notas</span>

                <v-icon right> mdi-file-pdf </v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-card>
        <v-col cols="12" class="pt-3 px-0">
          <div class="d-flex justify-space-between">
            <div>
              <v-btn color="success" @click="() => criarAula(gestaoDeAula)" class="mr-2"
                >Criar Aula</v-btn
              >
            </div>
            <v-text-field
              v-model="search"
              class="ml-2 search-input"
              append-icon="mdi-magnify"
              label="Pesquisar..."
              solo
              autofocus
              dense
              single-line
              hide-details
            />
          </div>
        </v-col>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="aulasList"
          :loading="carregandoAulas"
          :items-per-page="10"
          :custom-filter="dataTableFilter"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="aula.estaDeletado ? ['deleted'] : []"
                v-for="(aula, i) in items"
                :key="aula.id"
              >
                <td>{{ aula.id }}</td>
                <td>{{ aula.data.split("-").reverse().join("/") }}</td>
                <td class="d-flex align-center">
                  <e-situacao-aula :aula="aula" :i="i" @alterarSituacao="alterarSituacao" />
                  <v-chip
                    class="ml-2"
                    v-if="aula.descricaoSituacao === 'Aula em conflito'"
                    color="orange"
                    x-small
                    dark
                    @click="() => verificaSituacao(aula.id)"
                  >
                    ?</v-chip
                  >
                </td>

                <td><e-tipo-aula-snippet :aula="aula" /></td>
                <td>{{ aula.horario_inicial | hour }}</td>
                <td>
                  {{ aula.horario_final | hour }}
                </td>
                <td class="text-right">
                  <v-tooltip color="primary" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => verMotivoAula(aula)"
                      >
                        <v-icon small>fa fa-eye </v-icon>
                      </v-btn>
                    </template>
                    <span>Visualizar aula </span>
                  </v-tooltip>
                  <v-tooltip v-if="!aula.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1 white--text"
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="
                          aula.descricaoSituacao !== 'Aguardando confirmação'
                            ? !$can(['aulas.atualizarAposDefinirSituacao'])
                            : false
                        "
                        :to="{
                          name: 'aulas.frequencia',
                          params: { gestaoDeAulaId, aula_id: aula.id },
                        }"
                      >
                        <v-icon small>fa fa-thumbs-up </v-icon>
                      </v-btn>
                    </template>
                    <span>Lançar Frequência</span>
                  </v-tooltip>
                  <v-tooltip v-if="!aula.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="
                          aula.descricaoSituacao !== 'Aguardando confirmação' &&
                          aula.descricaoSituacao !== 'Aula em conflito'
                            ? true
                            : false
                        "
                        @click="() => editarAula(aula, gestaoDeAula)"
                      >
                        <v-icon small>fa fa-edit </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar Aula </span>
                  </v-tooltip>
                  <v-tooltip v-if="!aula.estaDeletado" color="error" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="
                          aula.descricaoSituacao !== 'Aguardando confirmação' &&
                          aula.descricaoSituacao !== 'Aula em conflito'
                            ? true
                            : false
                        "
                        @click="() => deletarAula(aula)"
                      >
                        <v-icon small>fa fa-trash </v-icon>
                      </v-btn>
                    </template>
                    <span>Apagar Aula </span>
                  </v-tooltip>
                  <v-tooltip v-if="aula.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="info"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => recuperarAula(aula)"
                      >
                        <v-icon small>fa fa-sync </v-icon>
                      </v-btn>
                    </template>
                    <span>Recuperar Aula </span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
      <v-bottom-sheet v-model="aulasEmConflitos" scrollable>
        <v-sheet class="text-center pa-md-8 mx-lg-auto" height="auto">
          <v-btn color="error" dark small @click="aulasEmConflitos = !aulasEmConflitos">
            Fechar
          </v-btn>

          <v-alert
            class="mt-2"
            style="text-align: center"
            type="orange"
            v-if="aulasConflitadas.length <= 0"
          >
            Procurando aulas em conflitos
          </v-alert>

          <h2 class="mt-4">Aulas conflitadas</h2>

          <!-- <div style="width: 100%; text-align: left">
            <v-chip color="orange" label dark><v-icon left> mdi-label </v-icon> </v-chip>
          </div> -->

          <v-simple-table class="mt-4" style="border: 1px solid #e0e0e0">
            <template>
              <thead style="background-color: #e0e0e0">
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">Professor</th>
                  <th class="text-left">Situação</th>
                  <th class="text-left">Data/Horario</th>
                  <th class="text-left">Turma</th>
                  <th class="text-left">Disciplina</th>
                </tr>
              </thead>
              <tbody class="text-left">
                <tr v-for="item in aulasConflitadas" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>
                    {{ item.professor.nome }}
                    <br />
                    <v-chip color="grey" dark x-small>
                      {{ item.turma.franquia.descricao }}
                    </v-chip>
                  </td>
                  <td>{{ item.situacao }}</td>
                  <td>
                    <span>
                      {{ item.data.split("-").reverse().join("/") }}
                    </span>
                    - {{ item.horario.descricao }}
                  </td>
                  <td>{{ item.turma.descricao }}</td>
                  <td>{{ item.disciplina.descricao }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-sheet>
      </v-bottom-sheet>
    </v-row>
    <e-drawer-motivo-aula v-model="verMotivo" :aula="aula"> </e-drawer-motivo-aula>
    <!-- Dialog -->
    <e-modal-select-month-aula
      :dialog="dialog"
      @dialogChange="dialog = $event"
      :gestao_id="gestaoDeAulaId"
      :tipo_diario="tipo_diario"
    ></e-modal-select-month-aula>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import { dataTableFilter } from "@/plugins/searchQuery";
import { dateFormatParam } from "../../plugins/filters";

export default {
  props: {
    gestaoDeAulaId: {
      required: true,
      type: [String, Number],
    },
  },
  data() {
    return {
      aulasConflitadas: [],
      aulasEmConflitos: false,
      dataTableFilter,
      aulasList: [],
      carregandoAulas: false,
      gestaoDeAulas: null,
      verMotivo: false,
      dialog: false,
      tipo_diario: 0,
      aula: {},
      gestaoDeAula: null,
      search: "",
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Data", value: "data_formatada" },
          { text: "Situação", value: "situacao" },
          { text: "Tipo", value: "tipo" },
          { text: "Início", value: "horario_inicial" },
          { text: "Fim", value: "horario_final" },
          {
            text: "Ações",
            value: "actions",
            sortable: false,
            width: "200px",
          },
        ],
      },
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  methods: {
    goBack() {
      this.$router.push({ name: "gestoesDeAulas" });
    },
    openModalMonth(tipo_diario) {
      this.dialog = true;
      this.tipo_diario = parseInt(tipo_diario, 10);
    },
    criarAula(gestaoDeAula) {
      // console.log(gestaoDeAula.turma.series[0].curso_id == 2 );
      this.$router.push({
        name:
          gestaoDeAula.turma.series[0].curso_id == 1 || gestaoDeAula.turma.series[0].curso_id == 2
            ? "aulasInfantis.create"
            : "aulas.create",
        params: {
          gestaoDeAulaId: gestaoDeAula.id,
        },
      });
    },
    async diarioNotas() {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.diarioNotas(this.gestaoDeAulaId);
        this.$emit("dialogChange", !this.dialog);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    verMotivoAula(aula) {
      this.verMotivo = !this.verMotivo;
      this.aula = aula;
    },
    editarAula(aula, gestaoDeAula) {
      this.$router.push({
        name:
          gestaoDeAula.turma.series[0].curso_id == 1 || gestaoDeAula.turma.series[0].curso_id == 2
            ? "aulasInfantis.edit"
            : "aulas.edit",
        params: { aula_id: aula.id },
      });
    },
    alterarSituacao(Objeto) {
      this.$set(this.aulasList, Objeto.i, Objeto.aulaAtualizada);
    },
    async verificaSituacao(aula_id) {
      this.aulasEmConflitos = !this.aulasEmConflitos;
      const data = await this.$services.aulasService.listarAulasEmConflitos(aula_id);
      this.aulasConflitadas = data.data;
    },
    async loadData() {
      this.carregandoAulas = true;
      this.$loaderService.open("Carregando Aulas");
      try {
        const response = await this.$services.gestoesDeAulasService.listarAulasGestao(
          this.gestaoDeAulaId
        );

        this.aulasList = response.aulas;

        // formatar data
        this.aulasList = this.aulasList.map((aula) => ({
          ...aula,
        }));

        this.gestaoDeAula = response.payload.gestaoDeAula;
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
      this.carregandoAulas = false;
    },
    async deletarAula(aula) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta aula?",
        confirmationCode: aula.id,
        confirmationMessage: `Por favor, digite <strong>${aula.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Aula");
              try {
                await this.$services.aulasService.deletar(aula);
                this.aulasList = this.aulasList.filter((item) => item.id !== aula.id);
                this.$toast.success("Aula deletada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              small: true,
            },
          },
        ],
      });
    },
    recuperarAula(aula) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta aula?",
        confirmationCode: aula.id,
        confirmationMessage: `Por favor, digite <strong>${aula.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a Aula");
              try {
                await this.$services.aulasService.recuperarAula(aula);
                this.$toast.success("Aula recuperada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
  },
};
</script>

<style></style>
