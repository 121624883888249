<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">Alunos</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-btn color="success" @click="criarAluno" class="mr-2">Criar Aluno</v-btn>
          <v-text-field
            v-model="search"
            class="ml-2 search-input"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            solo
            autofocus
            dense
            single-line
            hide-details
          />
        </div>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="alunosList"
          :loading="carregandoAlunos"
          :items-per-page="10"
          :custom-filter="dataTableFilter"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="aluno.estaDeletado ? ['deleted'] : []"
                v-for="aluno of items"
                :key="aluno.id"
              >
                <td>{{ aluno.id }}</td>
                <td>
                  <strong>{{ aluno.codigo ? aluno.codigo : "- - -" }}</strong>
                </td>
                <td>{{ aluno.codigo_inep ? aluno.codigo_inep : "- - -" }}</td>
                <td>{{ aluno.nomecompleto }}</td>
                <!-- <td>{{ aluno.contexto }}</td> -->
                <td>
                  <v-tooltip v-if="!aluno.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => editarAluno(aluno)"
                      >
                        <v-icon small>fa fa-edit </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar aluno</span>
                  </v-tooltip>
                  <v-tooltip v-if="!aluno.estaDeletado" color="error" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => deletarAluno(aluno)"
                      >
                        <v-icon small>fa fa-trash </v-icon>
                      </v-btn>
                    </template>
                    <span>Apagar aluno</span>
                  </v-tooltip>
                  <v-tooltip v-if="aluno.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="info"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => recuperarAluno(aluno)"
                      >
                        <v-icon small>fa fa-sync </v-icon>
                      </v-btn>
                    </template>
                    <span>Recuperar aluno</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>

        <e-paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :pageNumber="pageNumber"
          @changeActionResponse="listarAlunos"
          :search="search"
        ></e-paginate>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import { dataTableFilter } from "@/plugins/searchQuery";
import Aluno from "@/Models/Aluno";

export default {
  computed: {
    //
  },
  data() {
    return {
      carregandoAlunos: false,
      dataTableFilter,
      search: "",
      pageNumber: 0,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      alunosList: [],
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Código de acesso", value: "codigo" },
          { text: "Identificação Única(INEP)", value: "codigo_inep" },
          { text: "Nome", value: "nomecompleto" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },

  mounted() {
    this.carregandoAlunos = true;
    this.$loaderService.open("Carregando alunos");
    this.listarAlunos(this.pageNumber);
    this.carregandoGestoes = false;
    this.$loaderService.close();
  },

  watch: {
    carregandoAlunos(value) {
      if (value && this.alunosList.length === 0) {
        this.$loaderService.open("Carregando alunos");
      } else {
        this.$loaderService.close();
      }
    },

    async search(val) {
      if (val.length > 1) {
        await this.listarAlunos(0, val);
      }
      if (!val) {
        await this.listarAlunos(0);
      }
    },
  },

  methods: {
    async listarAlunos(pageNumber, query = null) {
      this.carregandoAlunos = true;
      try {
        const response = await this.$services.alunosService.syncAllPaginate(pageNumber, query);
        this.alunosList = response.data.map((aluno) => new Aluno(aluno));
        this.paginate = response;
      } catch (error) {
        this.$handleError(error);
      }
      this.carregandoAlunos = false;
    },

    criarAluno() {
      this.$router.push({ name: "alunos.create" });
    },

    editarAluno(aluno) {
      this.$router.push({ name: "alunos.edit", params: { aluno_id: aluno.id } });
    },

    async deletarAluno(aluno) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta aluno?",
        confirmationCode: aluno.id,
        confirmationMessage: `Por favor, digite <strong>${aluno.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Aluno");
              try {
                await this.$services.alunosService.deletarAluno(aluno);
                this.$toast.success("Aluno deletado com sucesso");
                this.$forceUpdate();
                this.listarAlunos(this.pageNumber);
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              small: true,
            },
          },
        ],
      });
    },

    recuperarAluno(aluno) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta aluno?",
        confirmationCode: aluno.id,
        confirmationMessage: `Por favor, digite <strong>${aluno.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a Aluno");
              try {
                await this.$services.alunosService.recuperarAluno(aluno);
                this.$toast.success("Aluno recuperada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              small: true,
            },
          },
        ],
      });
    },
  },
};
</script>

<style></style>
