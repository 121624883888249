<template>
  <main-template hide-franquia>
    <v-row class="mx-3">
      <v-col cols="12" class="mx-1">
        <v-img src="/img/portal_aluno.svg" style="max-width: 100%; height: auto" />
      </v-col>

      <v-col cols="12" class="mx-1">
        <small>Bem vindo(a)!</small>
        <h1>{{ this.nomeAluno }}</h1>
      </v-col>

      <v-col>
        <v-alert dismissible v-if="message" outlined type="error" prominent border="left">
          {{ message }}
        </v-alert>
        <h2>Minhas Matrículas</h2>
        <v-simple-table class="mt-3">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Matricula</th>
                <th class="text-left">Ano</th>
                <th class="text-left">Escola</th>
                <th class="text-left">Série</th>
                <th class="text-left">Turma</th>
                <th class="text-left">Visualizar</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="matricula in historicoAluno" :key="matricula.id">
                <td>{{ matricula.id }}</td>
                <td>{{ matricula.ano ? matricula.ano.descricao : "" }}</td>
                <td>{{ matricula.turma ? matricula.turma.franquia.descricao : "" }}</td>
                <td>{{ matricula.serie ? matricula.serie.descricao : "- - -" }}</td>
                <td>
                  <v-chip class="ma-2" color="green" text-color="white">
                    {{ matricula.turma ? matricula.turma.descricao : "- - -" }}
                  </v-chip>
                </td>

                <td width="20%" v-if="matricula.turma">
                  <v-tooltip color="blue-grey lighten-3" bottom offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="parseInt(matricula.turma.curso_id, 10) !== 1"
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        @click="boletim(matricula.id)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small class="ml-1">fa fa-file </v-icon>
                      </v-btn>
                    </template>
                    <span>Boletim</span>
                  </v-tooltip>
                  <v-tooltip color="blue-grey lighten-3" bottom offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        v-if="parseInt(matricula.turma.curso_id, 10) === 1"
                        color="blue-grey lighten-3"
                        @click="relatorioBncc(matricula.id)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small class="ml-1">fa fa-file </v-icon>
                      </v-btn>
                    </template>
                    <span>Relatório BNCC</span>
                  </v-tooltip>
                  <v-tooltip color="blue-grey lighten-3" bottom offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="openModalSelectMonths(matricula.id)"
                      >
                        <v-icon small class="ml-1">fa fa-address-book </v-icon>
                      </v-btn>
                    </template>
                    <span>Frequência</span>
                  </v-tooltip>
                  <v-tooltip color="blue-grey lighten-3" bottom offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="parseInt(matricula.turma.curso_id, 10) !== 10"
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        @click="desempenhoDoAluno(matricula.id)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small class="ml-1">fa fa-bar-chart</v-icon>
                      </v-btn>
                    </template>
                    <span>Desempenho</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <e-select-months-modal
      @dialogChange="dialog = $event"
      :dialog="dialog"
      confirmText="Gerar frequência"
      cancelText="Fechar"
      @changeAction="frenquenciaIndividual"
    ></e-select-months-modal>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  data() {
    return {
      nomeAluno: "",
      historicoAluno: [],
      message: null,
      dialog: false,
      matricula_selected: null,
    };
  },
  mounted() {
    this.matriculas();
    this.pegarNomeAluno();
  },
  methods: {
    openModalSelectMonths(matricula_id) {
      this.matricula_selected = matricula_id;
      this.dialog = !this.dialog;
    },
    async pegarNomeAluno() {
      this.nomeAluno = this.user.name.toLowerCase();
      const nome = this.nomeAluno.charAt(0).toUpperCase();
      this.nomeAluno = nome + this.nomeAluno.slice(1);
    },
    async matriculas() {
      const { aluno_id } = this.user;
      const response = await this.$services.matriculasService.historico(aluno_id);
      this.historicoAluno = response.data;
    },
    async boletim(matricula_id) {
      this.$loaderService.open("Carregando dados...");
      try {
        await this.$services.responsavelAlunoService.boletim(parseInt(matricula_id, 10));
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async frenquenciaIndividual(mes) {
      this.$loaderService.open("Carregando dados...");
      try {
        await this.$services.relatoriosService.frenquenciaIndividual(
          parseInt(this.matricula_selected, 10),
          parseInt(mes.id, 10)
        );
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async relatorioBncc(matricula_id) {
      try {
        this.$loaderService.open("Carregando dados");
        await this.$services.responsavelAlunoService.relatorioBncc(parseInt(matricula_id, 10));
        this.$loaderService.close();
      } catch (error) {
        this.$loaderService.close();
        this.message = "A turma não esta vinculado a um curso, entre em contato com a direção!";
      }
    },
    async desempenhoDoAluno(matricula_id) {
      this.$loaderService.open("Carregando dados...");
      try {
        this.$router.push({
          name: "matriculas.desempenhoNota",
          params: { matricula_id },
        });
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>
